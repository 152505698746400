@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-Medium.eot');
  src: url('WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-Medium.woff2') format('woff2'),
  url('WorkSans-Medium.woff') format('woff'),
  url('WorkSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-ExtraBoldItalic.eot');
  src: url('WorkSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-ExtraBoldItalic.woff2') format('woff2'),
  url('WorkSans-ExtraBoldItalic.woff') format('woff'),
  url('WorkSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-Light.eot');
  src: url('WorkSans-Light.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-Light.woff2') format('woff2'),
  url('WorkSans-Light.woff') format('woff'),
  url('WorkSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-Italic.eot');
  src: url('WorkSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-Italic.woff2') format('woff2'),
  url('WorkSans-Italic.woff') format('woff'),
  url('WorkSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-ExtraLightItalic.eot');
  src: url('WorkSans-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-ExtraLightItalic.woff2') format('woff2'),
  url('WorkSans-ExtraLightItalic.woff') format('woff'),
  url('WorkSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-ExtraLight.eot');
  src: url('WorkSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-ExtraLight.woff2') format('woff2'),
  url('WorkSans-ExtraLight.woff') format('woff'),
  url('WorkSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-LightItalic.eot');
  src: url('WorkSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-LightItalic.woff2') format('woff2'),
  url('WorkSans-LightItalic.woff') format('woff'),
  url('WorkSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-SemiBoldItalic.eot');
  src: url('WorkSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-SemiBoldItalic.woff2') format('woff2'),
  url('WorkSans-SemiBoldItalic.woff') format('woff'),
  url('WorkSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-SemiBold.eot');
  src: url('WorkSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-SemiBold.woff2') format('woff2'),
  url('WorkSans-SemiBold.woff') format('woff'),
  url('WorkSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-BlackItalic.eot');
  src: url('WorkSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-BlackItalic.woff2') format('woff2'),
  url('WorkSans-BlackItalic.woff') format('woff'),
  url('WorkSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-Regular.eot');
  src: url('WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-Regular.woff2') format('woff2'),
  url('WorkSans-Regular.woff') format('woff'),
  url('WorkSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-Black.eot');
  src: url('WorkSans-Black.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-Black.woff2') format('woff2'),
  url('WorkSans-Black.woff') format('woff'),
  url('WorkSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-ThinItalic.eot');
  src: url('WorkSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-ThinItalic.woff2') format('woff2'),
  url('WorkSans-ThinItalic.woff') format('woff'),
  url('WorkSans-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-MediumItalic.eot');
  src: url('WorkSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-MediumItalic.woff2') format('woff2'),
  url('WorkSans-MediumItalic.woff') format('woff'),
  url('WorkSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-Thin.eot');
  src: url('WorkSans-Thin.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-Thin.woff2') format('woff2'),
  url('WorkSans-Thin.woff') format('woff'),
  url('WorkSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-Bold.eot');
  src: url('WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-Bold.woff2') format('woff2'),
  url('WorkSans-Bold.woff') format('woff'),
  url('WorkSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-ExtraBold.eot');
  src: url('WorkSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-ExtraBold.woff2') format('woff2'),
  url('WorkSans-ExtraBold.woff') format('woff'),
  url('WorkSans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('WorkSans-BoldItalic.eot');
  src: url('WorkSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('WorkSans-BoldItalic.woff2') format('woff2'),
  url('WorkSans-BoldItalic.woff') format('woff'),
  url('WorkSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

