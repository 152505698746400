@import "/assets/variables";

.CriarEdicao {
  padding-top: 30px;
  padding-bottom: 40px;

  .create-edition-btn {
    margin-top: 50px;
    font-size: 48px;
    font-weight: 800;
    color: @color;
    cursor: pointer;
    display: inline-block;
  }
}