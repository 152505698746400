@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-Bold.eot');
  src: url('PublicSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-Bold.woff2') format('woff2'),
  url('PublicSans-Bold.woff') format('woff'),
  url('PublicSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-BoldItalic.eot');
  src: url('PublicSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-BoldItalic.woff2') format('woff2'),
  url('PublicSans-BoldItalic.woff') format('woff'),
  url('PublicSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-ExtraBold.eot');
  src: url('PublicSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-ExtraBold.woff2') format('woff2'),
  url('PublicSans-ExtraBold.woff') format('woff'),
  url('PublicSans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-Regular.eot');
  src: url('PublicSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-Regular.woff2') format('woff2'),
  url('PublicSans-Regular.woff') format('woff'),
  url('PublicSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-SemiBold.eot');
  src: url('PublicSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-SemiBold.woff2') format('woff2'),
  url('PublicSans-SemiBold.woff') format('woff'),
  url('PublicSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-BlackItalic.eot');
  src: url('PublicSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-BlackItalic.woff2') format('woff2'),
  url('PublicSans-BlackItalic.woff') format('woff'),
  url('PublicSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-ExtraLightItalic.eot');
  src: url('PublicSans-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-ExtraLightItalic.woff2') format('woff2'),
  url('PublicSans-ExtraLightItalic.woff') format('woff'),
  url('PublicSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-ExtraLight.eot');
  src: url('PublicSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-ExtraLight.woff2') format('woff2'),
  url('PublicSans-ExtraLight.woff') format('woff'),
  url('PublicSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-SemiBoldItalic.eot');
  src: url('PublicSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-SemiBoldItalic.woff2') format('woff2'),
  url('PublicSans-SemiBoldItalic.woff') format('woff'),
  url('PublicSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-MediumItalic.eot');
  src: url('PublicSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-MediumItalic.woff2') format('woff2'),
  url('PublicSans-MediumItalic.woff') format('woff'),
  url('PublicSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-ThinItalic.eot');
  src: url('PublicSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-ThinItalic.woff2') format('woff2'),
  url('PublicSans-ThinItalic.woff') format('woff'),
  url('PublicSans-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-Medium.eot');
  src: url('PublicSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-Medium.woff2') format('woff2'),
  url('PublicSans-Medium.woff') format('woff'),
  url('PublicSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-LightItalic.eot');
  src: url('PublicSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-LightItalic.woff2') format('woff2'),
  url('PublicSans-LightItalic.woff') format('woff'),
  url('PublicSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-Light.eot');
  src: url('PublicSans-Light.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-Light.woff2') format('woff2'),
  url('PublicSans-Light.woff') format('woff'),
  url('PublicSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-Italic.eot');
  src: url('PublicSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-Italic.woff2') format('woff2'),
  url('PublicSans-Italic.woff') format('woff'),
  url('PublicSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-ExtraBoldItalic.eot');
  src: url('PublicSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-ExtraBoldItalic.woff2') format('woff2'),
  url('PublicSans-ExtraBoldItalic.woff') format('woff'),
  url('PublicSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-Thin.eot');
  src: url('PublicSans-Thin.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-Thin.woff2') format('woff2'),
  url('PublicSans-Thin.woff') format('woff'),
  url('PublicSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Public Sans';
  src: url('PublicSans-Black.eot');
  src: url('PublicSans-Black.eot?#iefix') format('embedded-opentype'),
  url('PublicSans-Black.woff2') format('woff2'),
  url('PublicSans-Black.woff') format('woff'),
  url('PublicSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

