.FormEditarModulo {
  &.disabled {
    > * {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  h3 {
    margin-bottom: 16px;
  }

  .input-mod-container {
    position: relative;

    &:not(.compact) {
      margin-bottom: 40px;
    }
  }

  .input-number {
    position: absolute;
    right: 100%;
    margin-right: 8px;
    top: -1px;
    color: #000;

    &.with-title {
      top: 48px;
    }
  }

  .input-block-title {
    p {
      display: inline-block;
      font-weight: normal;
    }
  }

  .ArrayInput {
    .add-item {
      cursor: pointer;
      width: 22px;
      display: block;
    }
  }
}