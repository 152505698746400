@import "/assets/variables";

.Configuracao {
  padding-top: 30px;
  padding-bottom: 40px;


  .header_utilizador {
    padding: 20px 0;
  }

  .form-header {
    margin-bottom: 40px;
  }

  .notas {
    margin-top: 40px;
  }

  .modulo-actions {
    margin-top: 20px;

    .modulo-action {
      color: @color;
      font-size: 36px;
      text-decoration: underline;
      cursor: pointer;
      margin-left: 20px;

      &.bold {
        font-weight: 800;
      }
    }
  }

  @media (max-width: 768px) {
    .items-carousel-item-wrapper > .d-flex {
      flex-direction: column;
    }
  }

  .Collapsible__trigger {
    cursor: pointer;
  }

  .Collapsible__contentOuter {
    margin-top: -90px;
    margin-bottom: 90px;
  }


  .formulario_utilizador {
    padding-left: 30px;
    margin-top: 80px;

    @media (max-width: 768px) {
      padding-left: 0;
    }

    .seccoes {
      margin-top: 30px;
      display: flex;

      p {
        margin-bottom: 7px;
      }

      label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "Noto Serif", sans-serif;
        font-style: italic;
        text-decoration: underline;
        font-weight: 300;
        padding: 4px 0;
        cursor: pointer;

        input {
          margin-left: 16px;
        }

        input::placeholder {
          color: #C1BFBF;
          font-weight: 800;
        }
      }

      div {
        display: flex;
        flex-direction: column;
        margin-top: -0.2em;

        &:not(:first-child) {
          margin-left: 30px;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;

        div {
          flex: 1;
          margin-left: 0 !important;
        }
      }
    }
  }
}