@import "/assets/variables";

.WizardRevisao {
  flex: 1;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 70px;
  }

  .items-carousel-item-wrapper {
    width: 100%;

    h3 {
      &.template-header {
        font-weight: 300;
        font-size: 28px;
      }
    }
  }

  .passos {
    position: absolute;
    top: 140px;
    left: 0;
    font-size: 30px;
    font-weight: 100;

    @media (max-width: 768px) {
      top: 0;
      right: 0;
      left: auto;
    }

    p {
      display: inline-block;
      font-weight: normal;
      font-size: inherit;
      color: gray;
      cursor: default;
      padding: 5px;

      &.active {
        color: @color;
      }
    }
  }

  .preview {
    width: 100%;
  }
}