.Footer {
  a {
    color: #000;
    text-decoration: none;
  }

  .links-container {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    .link {
      display: inline-block;
      margin-left: 30px;
      cursor: pointer;

      @media (max-width: 768px) {
        font-size: 0.8em;
      }
    }
  }
}