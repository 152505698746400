.InputModulo {
  position: relative;
  margin-bottom: 10px;

  .placeholder {
    position: absolute;
    line-height: 30px;
    pointer-events: none;
  }

  textarea, .react-datepicker-wrapper, .react-datepicker-wrapper input, select {
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) calc(50% - 1px), rgb(0 0 0) calc(50%), rgb(0 0 0) calc(50% + 1px), rgba(0, 0, 0, 0) calc(50% + 1px));
    background-size: 15px 30px;
    line-height: 30px;
    background-position: top 12px center;
    background-color: transparent;
    border: none;
    position: relative;
    resize: none;
    overflow: hidden;
    min-height: inherit;
    color: inherit;
    font-family: "Noto Serif", sans-serif;
  }

  .react-datepicker-wrapper, .react-datepicker-wrapper .react-datepicker__input-container {
    height: 100%;
  }

  &.with-error {
    color: #B92C00;

    p {
      color: inherit;
    }

    textarea {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) calc(50% - 1px), #B92C00 calc(50%), #B92C00 calc(50% + 1px), rgba(0, 0, 0, 0) calc(50% + 1px));
    }

    .error {
      text-align: right;
      // font-size: 80%;
      margin-top: 4px;
      font-style: italic;
    }
  }

  .placeholder, .input-image p {
    color: gray;

    .title {
      font-family: "Public Sans", sans-serif;
      font-style: normal;
    }
  }

  label.input-image, .input-image label {
    display: block;
    cursor: pointer;
  }

  .input-image {
    align-items: flex-end;
    margin-bottom: 20px;

    p {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) calc(50% - 1px), rgb(0 0 0) calc(50%), rgb(0 0 0) calc(50% + 1px), rgba(0, 0, 0, 0) calc(50% + 1px));
      background-size: 15px 30px;
      background-position: top 12px center;
      background-repeat: repeat-x;
      padding: 3px 0;
      padding-right: 10px;
      height: 28px;
    }

    input {
      display: none;
    }

    .img {
      width: 150px;
      height: 120px;
      background-position: center;
      background-size: cover;
      margin-right: 15px;
      border: solid 1px black;
    }
  }

  .input-breve {
    margin-bottom: 20px;

    h3 {
      font-size: 22px;
      margin-bottom: 10px;
    }

    textarea {
      margin-bottom: 8px;
    }
  }

  .input-select {
    h3 {
      font-size: 22px;
    }

    label {
      border: 2px solid black;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      position: relative;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        width: 18px;
        height: 100%;
        background-image: url(./chevron_down.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.open {
        z-index: 3;

        &:after {
          transform: scaleY(-100%);
        }
      }
    }

    .backdrop {
      position: fixed;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: white;
      z-index: 3;
      border: 2px solid black;
      border-top-color: white;
      border-top: none;
      overflow: auto;
      max-height: 200px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.3s;
      transform: translateY(-5px);
      opacity: 0;
      pointer-events: none;
      margin-top: -2px;

      &.open {
        transform: translateY(0);
        opacity: 1;
        pointer-events: auto;
      }

      span {
        padding: 5px 10px;
        opacity: 0.5;
        display: block;

        &:hover {
          opacity: 0.8;
          background-color: #f8f8f8;
        }

        &.active {
          opacity: 1;
        }
      }
    }
  }
}