.InputEdicao {
  label {
    cursor: pointer;
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;

    > div:first-child {
      p {
        margin-top: 16px;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .options-container {
    display: flex;
    align-items: center;
    margin-right: 40px;

    @media (max-width: 768px) {
      margin: 0;
      margin-top: 20px;
    }
  }

  .options-container.tipo {
    label {
      flex-direction: column;
      margin-left: 50px;
      font-style: normal;

      input[type=radio]:checked ~ * {
        opacity: 1;
      }

      input[type=radio]:not(:checked) ~ * span {
        color: black !important;
      }

      h3, p {
        opacity: 0.2;
      }

      p {
        margin-top: 10px
      }

      @media (max-width: 768px) {
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }

  .options-container.cor {
    label {
      margin-left: 20px;

      > span {
        width: 60px;
        height: 60px;
        display: block;
        opacity: 0.3;
        transition: transform 0.3s, opacity 0.3s;

        &:hover {
          transform: scale(1.1);
          opacity: 1;
        }
      }

      @media (max-width: 768px) {
        margin-left: 0;
        margin-right: 10px;

        > span {
          width: 40px;
          height: 40px;
        }
      }
    }

    input[type=radio]:checked + span {
      opacity: 1;
    }
  }

  .options-container.datas {
    flex-direction: column;

    .datepicker-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Noto Serif", sans-serif;
      font-style: italic;
      text-decoration: underline;
      font-weight: 300;
      margin-bottom: 8px;
    }
  }

  .options-container.seccoes {
    label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Noto Serif", sans-serif;
      font-style: italic;
      text-decoration: underline;
      font-weight: 300;
      margin-bottom: 6px;

      input {
        margin-left: 16px;
      }

      input::placeholder {
        color: #C1BFBF;
        font-weight: 800;
      }
    }

    div {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-left: 30px;
      }
    }

    @media (max-width: 768px) {
      div {
        flex: 1;
      }
    }
  }

  input[type=radio] {
    display: none;
  }

  .react-datepicker-wrapper {
    margin-left: 20px;
  }

  .react-datepicker__input-container {
    input {
      font-size: 1rem;
      font-family: "Work Sans", sans-serif;
      font-weight: 700;
      letter-spacing: 0.05em;
      width: 8em;
    }

    input::placeholder {
      color: #c1bfbf;
    }
  }

  .error {
    margin-top: -10px;
    margin-bottom: 30px;
    color: #B92C00;
    text-align: right;
  }
}

.InputEdicao:last-child {
  .line {
    display: none;
  }
}