@import "/assets/variables";

.IntroInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;

  .intro-message {
    width: 50%;
    max-width: 500px;
    margin-right: 30px;
  }

  .next-ed-deadline {
    padding-top: 20px;

    .days-left {
      color: @color;
    }

    i {
      color: @color;
      font-size: 30px;
    }

    p {
      margin-left: 8px;
      margin-bottom: 10px;
    }
  }

  .toggle {
    margin-top: 20px;
    display: block;
    cursor: pointer;

    p {
      display: inline;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .intro-message {
      width: 100%;
    }
  }
}