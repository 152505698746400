@import "/assets/variables";

.GerirSubscricao {

  .seccoes {
    margin-top: 30px;
    display: flex;

    p {
      margin-bottom: 7px;
    }

    label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Noto Serif", sans-serif;
      font-style: italic;
      text-decoration: underline;
      font-weight: 300;
      padding: 4px 0;
      cursor: pointer;

      input {
        margin-left: 16px;
      }

      input::placeholder {
        color: #C1BFBF;
        font-weight: 800;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      margin-top: -0.2em;

      &:not(:first-child) {
        margin-left: 30px;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;

      div {
        flex: 1;
        margin-left: 0 !important;
      }
    }
  }

  .guardar {
    margin-top: 80px;
    font-size: 48px;
    font-weight: 800;
    color: @color;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 40px;
  }
}