.Edicoes {

  h1 {
    margin-right: 40px;
  }

  .edition-collapsible {
    display: flex;
  }

  .editions-list {
    display: flex;
    padding: 35px 0;

    h1 {
      opacity: 0;
    }

    .editions-marker {
      margin: 0 auto;
      font-weight: 200;
    }

    .editions {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      align-items: self-start;
      width: 68%;

      .create-edition {
        width: calc(33.3333% - 40px);
        margin-left: 40px;
      }
    }

    .create-edition {
      display: flex;
      margin-top: -0.25em;
      cursor: pointer;
      align-items: center;

      h2 {
        display: inline;
        margin-right: 10px;
      }

      span {
        margin-top: 3px;
        font-family: "Work Sans", sans-serif;
        font-size: 28px;
        font-weight: 600;
      }

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }

    @media (max-width: 1024px) {
      flex-direction: column;

      .editions {
        width: auto;
        margin-left: -40px;
        margin-top: 60px;
      }
    }
  }

  .Collapsible__trigger {
    height: 110px;
    display: block;
    cursor: pointer;

    h1 {
      position: relative;
      z-index: 1;
      margin-top: 6px;
    }

    .arrow-down {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #000;
      transition: all .3s;
      transform: rotate(90deg) translateX(5px);
      margin-left: -8px;
      position: relative;
      z-index: 1;
    }

    &.is-open {
      .arrow-down {
        margin-left: 4px;
        transform: none;
      }
    }
  }


  .Collapsible__contentOuter {
    margin-top: -110px;
    margin-bottom: 110px;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s !important;
  }

  .Collapsible__trigger.is-open + .Collapsible__contentOuter {
    transform: translateY(0px);
    opacity: 1;
  }
}