@import "/assets/variables";

.EditarModulo {
  .field-container {
    position: relative;

    .field-number {
      position: absolute;
      left: -24px;
      bottom: 6px;
      font-size: 24px;
      font-weight: 100;
    }
  }

  .column {
    width: 50%;
    flex: 1;
    padding: 0 42px;

    @media (max-width: 768px) {
      padding: 0 20px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    @media (max-width: 768px) {
      padding: 0;
      width: 100%;
      padding-bottom: 60px;
    }

    .form-header {
      margin-bottom: 40px;
    }

    .notas {
      margin-top: 40px;
    }

    .modulo-actions {
      margin-top: 20px;

      .modulo-action {
        color: @color;
        font-size: 36px;
        text-decoration: underline;
        margin-right: 40px;
        cursor: pointer;

        &.bold {
          font-weight: 800;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .items-carousel-item-wrapper > .d-flex {
      flex-direction: column;
    }

    flex-direction: column;
  }

  .notas_seccao {
    .InputModulo p.placeholder {
      color: @color;
    }
  }
}