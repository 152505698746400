.EditarEdicao {
  .modulos {
    margin: 40px 0;

    .Collapsible__trigger {
      .status {
        opacity: 1;
        transform: translateY(0px);
        transition: all 0.5s;
      }

      &.is-open {
        .status {
          opacity: 0;
          transform: translateY(-10px);
        }
      }
    }

    .info-modulo {
      margin-right: 38px;
      min-width: 196px;

      @media (max-width: 768px) {
        margin-right: 25px;
        min-width: 125px;
      }
    }

    .Collapsible__contentOuter {
      padding-left: 200px;
      margin-top: -130px;
      margin-bottom: 130px;
      opacity: 0;
      transform: translateY(20px);
      transition: all 0.5s !important;

      @media (max-width: 768px) {
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .Collapsible__trigger.is-open + .Collapsible__contentOuter {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .header_modulo {
    padding: 25px 0;

    cursor: default;

    &:not(.is-disabled ) {
      cursor: pointer;
    }


  }
}