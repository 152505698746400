@import "assets/fonts/fonts.less";

body {
  margin: 0;
  font-family: "Public Sans", sans-serif;
}

* {
  box-sizing: border-box;
}

.container {
  width: 90%;
  max-width: 1200px;
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

p {
  margin: 0;
}

h1 {
  margin: 0;
  margin-top: -0.25em;
  font-family: "Work Sans", sans-serif;
  font-size: 65px;

  &.small {
    font-size: 56px;
  }

  @media (max-width: 768px) {
    font-size: 65px*0.6;

    &.small {
      font-size: 56px*0.7;
    }
  }
}

h2 {
  margin: 0;
  font-size: 56px;
  margin-top: -0.2em;
  font-family: "Work Sans", sans-serif;
  letter-spacing: -0.04em;

  &.small {
    font-size: 36px;
  }

  @media (max-width: 768px) {
    font-size: 56px*0.7;

    &.small {
      font-size: 36px*0.7;
    }
  }
}

h3 {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  font-size: 56px;
  font-weight: 600;
  line-height: 1;

  &.small {
    font-size: 36px;
  }

  @media (max-width: 768px) {
    font-size: 56px*0.7;

    &.small {
      font-size: 36px*0.7;
    }
  }
}

h4 {
  margin: 0;
  font-size: 32px;
  margin-top: -0.2em;
  font-family: "Work Sans", sans-serif;
  letter-spacing: -0.04em;

  &.small {
    font-size: 25px;
  }

  @media (max-width: 768px) {
    font-size: 32px*0.7;

    &.small {
      font-size: 25px*0.7;
    }
  }
}


h1, h2, h3, h4, p {
  &.bolder {
    font-weight: 800;
  }

  &.bold {
    font-weight: bold;
  }

  &.normal {
    font-weight: normal;
  }

  &.light {
    font-weight: 200;
  }
}

p, a, textarea, input {
  font-size: 15px;

  .serif, &.serif {
    font-family: "Noto Serif", sans-serif;
  }

  &.italic {
    font-style: italic;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &.link {
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }
}


@media (max-width: 768px) {
  p, a, label {
    font-size: 0.8em;
  }
}

h1 {
  &.bold {
    font-weight: 900;
  }
}

textarea {
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  margin-left: -2px;
}

.line, hr {
  border: none;
  border-top: 1px solid #000;
  flex: 1;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.close-icon {
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(./assets/plus.svg);
  transition: all 0.3s;
  z-index: 1;
}

.Collapsible__trigger.is-open {
  .close-icon {
    transform: rotate(45deg);
  }
}

.flex-direction-row {
  flex-direction: row;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.no-br {
  white-space: nowrap
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .hide-desktop {
    display: none !important;
  }
}

input[type=text], input[type=password] {
  padding: 0 0 2px 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: 1px;
  border-bottom-color: #000;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
}

.alert-popup {
  border: solid black 1px;
  background-color: white;
  width: 85%;
  max-width: 800px;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-45%);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s;
  z-index: 10;

  &.show {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(-50%);
  }

  .close {
    display: block;
    margin-left: auto;
    width: 30px;
    height: 30px;
    background-image: url(assets/plus.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(45deg);
    cursor: pointer;
  }

  p {
    margin: 30px 0;
  }

  .buttons {
    text-align: center;
    margin-bottom: 20px;

    a {
      display: inline-block;
      border: solid black 1px;
      margin: 0 15px;
      padding: 10px 20px;
      min-width: 25%;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        background-color: #f6f6f6;
      }

      @media (max-width: 768px) {
        padding: 7px 12px;
        margin: 0 10px;
      }
    }
  }

  .Loading {
    > div > div > div {
      padding: 30px;

      @media (max-width: 768px) {
        padding: 20px;
      }
    }

    .loading-svg {
      padding: 80px;

      svg {
        width: 100px;
      }
    }
  }
}

.react-datepicker {
  border-color: #000;
  border-radius: 0;

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: white;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #000;
}

input[type=checkbox] {
  display: none;

  & + span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: solid 1px black;
    margin-left: 12px;
  }

  &:checked + span {
    background-color: black;
  }
}