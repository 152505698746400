.Loading {
  .cross-fade-leave {
    opacity: 1;
  }

  .cross-fade-leave.cross-fade-leave-active {
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  .cross-fade-enter {
    opacity: 0;
  }

  .cross-fade-enter.cross-fade-enter-active {
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }

  .cross-fade-height {
    transition: height 0.5s ease-in-out;
  }

  .loading-svg {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 200px;
    }

    svg .svg-elem-1 {
      stroke-dashoffset: 792.8037719726562px;
      stroke-dasharray: 792.8037719726562px;
      animation: loading-svg 2s ease-out;
      animation-fill-mode: forwards;
    }

    &.delay svg .svg-elem-1 {
      animation-delay: 0.5s;
    }

    &.small {
      margin-top: 0;
      padding: 20px;

      svg {
        width: 60px;
      }
    }
  }

  @keyframes loading-svg {
    to {
      stroke-dashoffset: 1585.6075439453125px;
    }
  }
}