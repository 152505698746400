.RecuperarPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 85vh;
  margin: -70px auto auto;

  img {
    max-width: 460px;
    width: 80%;
    margin-bottom: 30px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 460px;
    width: 80%;

    .campos {
      display: flex;
      margin-bottom: 15px;
      width: 100%;
      align-items: self-start;

      input{
        width: 100%;
        font-family: "Noto Serif", sans-serif;
        font-size: 18px;
      }
      .mostrarPassword {
        background-color: rgba(255, 255, 255, 0);
        border: none;

        img {
          height: 25px;
          width: 25px;
        }
      }
    }
    .submeter{
      background-color: rgba(255, 255, 255, 0);
      border: none;
      font-family: "Noto Serif", sans-serif;
      color: #000;
      font-weight: bold;
      font-style: italic;
      font-size: 15px;
    }
  }
}