.Header_roll {
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
  text-align: right;
  border-bottom: 1px solid black;
  background-color: white;
  z-index: 2;

  .marquee p {
    flex: 1;
    font-style: italic;
    margin: 7px 0;
    font-size: 1em;
    padding-left: 20vw;
    font-weight: normal;
  }
}

.Header {
  display: flex;
  margin-top: 60px;
  margin-bottom: 30px;

  img {
    width: 80px;
  }

  .logo-na {
    min-width: 220px;
    height: 66px;
  }
}

.flex-space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: end;
}