.Edicao {
  width: calc(33.3333% - 40px);
  margin-left: 40px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    width: calc(50% - 40px);
    margin-left: 40px;
  }

  .edition-type {
    margin-bottom: 14px;
  }

  .edition-data {
    .edition-month {
      text-transform: uppercase;
      font-weight: 300;
    }

    .edition-title {
      margin-top: 4px;
    }
  }

  .edition-actions {
    margin-top: 20px;

    div {
      display: flex;
      align-items: center;
      font-size: 14px;

      .closed-edition {
        text-decoration: line-through;
      }
    }

    div.open-edition {
      font-style: italic;
    }

    a {
      font-weight: 600;
      margin: 0 5px;
    }
  }

  .finished {
    opacity: 0.2;
  }

  .has-actions {
    font-weight: normal;
    font-size: 3em;
    margin-left: 18px;
  }
}