.PreviewPDF {
  margin: 0 -7%;
  position: relative;

  .image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(branco.svg);
    transition: opacity 0.3s, background-image 1s, padding-bottom 1s;
  }

  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0;
    transition: all 1s;

    &:after {
      content: '';
      background-image: url("../../assets/logo_compacto.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 60px;
      height: 60px;
      display: block;
      animation: 1.6s linear 0s infinite normal forwards running bounce;
    }
  }

  &.loading {
    .loader {
      opacity: 1;
    }
  }

  @keyframes bounce {
    0% {
      animation-timing-function: cubic-bezier(0.1361, 0.2514, 0.2175, 0.8786);
      transform: translate(0, 0px) scaleY(1);
    }
    37% {
      animation-timing-function: cubic-bezier(0.7674, 0.1844, 0.8382, 0.7157);
      transform: translate(0, -50%) scaleY(1);
    }
    72% {
      animation-timing-function: cubic-bezier(0.1118, 0.2149, 0.2172, 0.941);
      transform: translate(0, 0px) scaleY(1);
    }
    87% {
      animation-timing-function: cubic-bezier(0.7494, 0.2259, 0.8209, 0.6963);
      transform: translate(0, 25%) scaleY(0.602);
    }
    100% {
      transform: translate(0, 0px) scaleY(1);
    }
  }
}