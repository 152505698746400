.Login {
  min-height: 85vh;
  margin: -70px auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: 10px;
  }

  img {
    max-width: 460px;
    width: 80%;
    margin-bottom: 30px;
  }

  form {
    margin: 0;
    display: flex;
    flex-direction: column;
    max-width: 460px;
    width: 80%;

    input {
      font-size: 18px;
    }

    input::placeholder {
      color: #D1D0CF;
      font-family: "Noto Serif", sans-serif;
      font-size: 18px;
      font-style: italic;
    }

    .password {
      margin-top: 26px;
    }

    .submit-login {
      opacity: 0;
      pointer-events: none;
    }
    .change_password{
      border: none;
      font-family: "Noto Serif", sans-serif;
      font-size: 18px;
      font-style: italic;
      margin-top:10px;
      color: #000;
      font-weight: bold;
      background-color: #ffffff;
      cursor:pointer;
    }
  }

  .extra-links {
    margin-top: 6px;
    display: flex;
    justify-content: space-between;

    a {
      color: #000;
      font-weight: bold;
      font-style: italic;
    }
  }
}
